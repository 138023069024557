


















































































import {Component, Vue, Watch} from 'vue-property-decorator';

import {collectionURLs} from '@/utils/config';
import {UserStore} from '@/store/modules/user';
import {searchHelper} from '@/utils/search';
import {scrollToTop} from '@/utils/scroll';

@Component
export default class AdvancedSearch extends Vue {

  total = 0;
  page = 0;
  query = '';
  facets = [];
  hits = [];
  pageCount = 0;
  loading = true;

  bookmarking: { [key: string]: boolean } = {};
  facetActive: { [key: string]: boolean } = {};
  activeNames = [0, 1];

  edit(collection: string, id: string) {
    this.$router.push(`${collectionURLs[collection] || ''}/edit/${id}`);
  }

  // Bookmark Functions
  bookmark(id: string, collection: string, question: string) {
    this.$set(this.bookmarking, id, true);
    if (this.bookmarked(id)) {
      return UserStore.removeBookmark(id)
        .then(() => this.$set(this.bookmarking, id, false))
        .catch(() => this.$set(this.bookmarking, id, false));
    } else {
      return UserStore.addBookmark({collection, id, question})
        .then(() => this.$set(this.bookmarking, id, false))
        .catch(() => this.$set(this.bookmarking, id, false));
    }
  }

  bookmarked = (id: string) => !!UserStore.bookmarks[id];

  bookmarkState(id: string) {
    if (this.bookmarking[id]) {
      return 'el-icon-loading';
    } else {
      return this.bookmarked(id) ? 'el-icon-star-on' : 'el-icon-star-off';
    }
  }

  // Search Functions
  @Watch('query')
  search() {
    if (this.query.length >= 3) {
      searchHelper.setQuery(this.query).search();
    }
  }

  toggleFacet(attribute: string, value: string) {
    searchHelper.toggleRefine(attribute, value).search();
    this.facetActive[attribute + value] = !this.facetActive[attribute + value];
  }

  pageChange(page: number) {
    if (page < 1) {
      return;
    }
    searchHelper.setPage(page - 1).search();
    scrollToTop();
  }

  reset(refiners = true) {
    this.loading = true;
    if (refiners) {
      searchHelper.clearRefinements().search();
      this.facetActive = {};
    } else {
      searchHelper.setQuery('').search();
      this.query = '';
    }
  }

  isHighlighted = (content: string) => content.indexOf('search-highlight') >= 0;

  created() {
    searchHelper.on('result', (results: any) => {
      this.facets = results.facets;
      this.total = results.nbHits;
      this.pageCount = results.nbPages;
      this.hits = results.hits;
      this.loading = false;
      this.saveState();
    });
    if (this.retrieveState()) {
      const state: any = this.retrieveState();
      state.query = state.query || '';
      searchHelper.setState(state).search();
      this.query = state.query;
      this.page = state.page;
      for (const attribute in state.facetsRefinements) {
        if (state.facetsRefinements.hasOwnProperty(attribute)) {
          this.facetActive[attribute + state.facetsRefinements[attribute]] = true;
        }
      }
    } else {
      searchHelper.setQuery('').search();
    }
  }

  destroyed() {
    this.saveState();
    searchHelper.removeListener('result', () => {
      // remove listener
    });
  }

  saveState = () => sessionStorage.setItem('searchState', JSON.stringify(searchHelper.getState() || {}));

  retrieveState() {
    const state = sessionStorage.getItem('searchState');
    if (state) {
      return JSON.parse(state);
    }
    return;
  }

}
